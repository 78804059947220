.health-record-list {
  display: flex;
  flex-direction: column;
}

.health-record-list-item {
  text-decoration: none;
  color: black;
}

.species-icon {
  margin-right: 2em;
  margin-bottom: 1em;
  width: 2em;
}

.details {
  width: 100%;
}

.details .location {
  font-size: 1.2em;
  line-height: 1.5;
}

.assessment-icon.a {
  background: black;
  padding: 0 3px;
  color: white;
  font-weight: bolder;
  font-size: 0.75em;
}

.details .assessment,
.details .reported-on {
  line-height: 1.25;
}

.arrow {
  font-size: 48px;
  align-self: center;
  margin: 0 10px;
  text-decoration: none;
  color: black;
}

.approval-link {
  display: flex;
  text-decoration: none;
  color: black;
  background: yellow;
  margin: 10px;
  padding: 10px;
  align-items: center;
}

.approval-link .text {
  flex: 1;
}

.approval-link .arrow {
  flex: 0;
}

.update {
  border: 1px solid black;
  margin: 10px 0;
  padding: 10px;
}

.action-menu-icon {
  position: fixed;
  bottom: 100px;
  right: 20px;
}

.action-menu-items {
  position: fixed;
  bottom: 168px;
  right: 20px;
}

.treatment {
  border-left: 2px solid black;
  padding-left: 1em;
}

.field {
  padding-right: 2em;
  padding-bottom: 1em;
}

.dropzone {
  border: 1px dashed black;
  padding: 1em;
  text-align: center;
}

.breadcrumbs-link {
  color: #276EF1;
}
